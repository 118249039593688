// import React from "react";

// import Header from '../components/Header/Header';
// import Hero from '../components/Hero/Hero';
// import ShopComingSoon from '../components/ShopComingSoon/ShopComingSoon';
// import Footer from '../components/Footer/Footer';

// import BgDesktopSrc from '../images/hero-fire-bg-desktop.jpg';
// import BgMobileSrc from '../images/hero-fire-bg-mobile.jpg';

// const TermsPage = () => {
//   return (
//     <div
//       style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
//     >
//       <Header />
//       <Hero
//         img={{ mobile: BgMobileSrc, desktop: BgDesktopSrc }}
//         title="Shop&nbsp;-&nbsp;Coming&nbsp;Soon"
//       />
//       <ShopComingSoon />
//       <Footer />
//     </div>
//   );
// };

// export default TermsPage;

import React from 'react';

import Header from '../components/Header/Header';
import HeroTheLegend from '../components/HeroTheLegend/HeroTheLegend';
import SEO from '../components/SEO/SEO';
import CookieDisclaimer from '../components/CookieDisclaimer/CookieDisclaimer';

const isBrowser = typeof window !== 'undefined';

const Shop = () => {
  if (isBrowser) {
    window.location.replace('https://shop.jamesownbyreserve.com/');
  }
  return (
    <>
      <SEO pageName="shop" />
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Header />
        <main id="main" tabIndex="-1">
          <HeroTheLegend title="Redirecting to shop..." isNotFound />
        </main>
      </div>
      <CookieDisclaimer />
    </>
  );
};

export default Shop;
